h1,
h2,
h3 {
  font-family: "Roboto", Helvetica, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

a {
  background-image: linear-gradient(0deg, currentColor, currentColor);
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: 0 2px;
  color: #e57a44;
  text-decoration: none;
  transition: background 500ms ease;
}
a:hover, a:focus {
  background-size: 100% 2px;
}