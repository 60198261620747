/* breakpoints.scss */
.three-up {
  padding-bottom: 30px;
  padding-top: 30px;
}
.three-up__container {
  display: grid;
  grid-template-columns: 1;
  grid-template-rows: auto auto auto;
  grid-gap: 20px;
}
@media (min-width: 621px) {
  .three-up__container {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
  }
}
@media (min-width: 971px) {
  .three-up__container {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
  }
}
.three-up__item {
  display: flex;
  flex-direction: column;
  padding: 0 10px 24px;
  position: relative;
  z-index: 2;
}
.three-up__item::before {
  background-color: #fff;
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: -10px;
  left: 5px;
  transform: perspective(900px) translateZ(-200px) rotate3d(-1, -1, -0.25, 8deg) skew(-1.5deg) scale(1.2);
  z-index: -1;
}
.three-up__image {
  flex: none;
}
.three-up__content {
  display: flex;
  flex: auto;
  flex-direction: column;
  padding-left: 24px;
  padding-right: 16px;
}
.three-up__summary {
  margin-bottom: 24px;
}
.three-up__cta {
  align-self: flex-end;
  margin-top: auto;
  margin-right: -16px;
}