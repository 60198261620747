.social-links__items {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  margin-left: -15px;
  padding: 0;
}
.social-links__item {
  border: 2px solid white;
  height: 45px;
  margin-left: 15px;
  transition: background 500ms ease;
  width: 45px;
}
.social-links__item:hover {
  background-color: rgba(255, 255, 255, 0.3);
}
.social-links__link {
  background-image: none;
  display: block;
  padding: 8px;
}
.social-links__link svg {
  height: 100%;
  width: 100%;
}