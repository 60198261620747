html {
  height: 100%;
}

body {
  background-color: #87cefa;
  color: #222;
  font-family: "Roboto Slab", "Georgia", "Times New Roman", "Times", serif;
  display: flex;
  flex-direction: column;
  margin: 0;
  min-height: 100vh;
  overflow-x: hidden;
  padding: 0;
  position: relative;
}
body > header, body > footer {
  flex: none;
}
body > main {
  flex: auto;
}

* {
  box-sizing: border-box;
}

img {
  height: auto;
  max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
p {
  margin-bottom: 0;
}