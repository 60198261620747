.global-footer {
  margin-top: 85px;
  overflow: hidden;
  padding: 50px 0;
  position: relative;
  z-index: 2;
}
.global-footer::before {
  background-color: #41c560;
  border-radius: 50%/5%;
  content: "";
  height: 200%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
}
.global-footer__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.global-footer__copyright {
  color: white;
  font-family: "Roboto", Helvetica, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}