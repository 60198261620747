.paper-wrapper__container {
  background-color: white;
  background-image: repeating-linear-gradient(0deg, #f3f3f3, #f3f3f3 1px, transparent 1px, transparent 1.5em);
  background-position: left 40px top 50px;
  background-repeat: no-repeat;
  background-size: calc(100% - 80px) calc(100% - 100px);
  border-left: 10px dotted #eee;
  font-size: 22px;
  line-height: 1.5;
  margin-top: 70px;
  max-width: 80ch;
  outline: 5px solid white;
  padding-top: 50px;
  padding-bottom: 50px;
}