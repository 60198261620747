@import "../00-variables/_colors.scss";
@import "../00-variables/_fonts.scss";
@import "../00-variables/_breakpoints.scss";
@import "../00-variables/_z-index.scss";
@import "../00-variables/mixins/_button-reset.scss";

.global-header {
  font-family: $font-sans-serif;
  padding-top: 5px;
  position: relative;
  z-index: $z-sticky-content;

  @supports (position: sticky) {
    position: sticky;
    top: 0;
  }

  &::before {
    border-bottom: 10px dotted #eee;
    background-color: white;
    content: "";
    height: 200%;
    outline: 2px solid white;
    position: absolute;
    bottom: 0;
    left: 0;
    transform: rotate(-1deg) skew(-1deg);
    transform-origin: right bottom;
    width: 100%;
    z-index: -1;
  }

  &__skip-link {
  }

  &__logo {
    font-size: 28px;

    a {
      color: inherit;
    }
  }

  &__container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    height: 48px;

    @media ($bp-header-max) {
      position: relative;
    }
  }

  @media ($bp-header-max) {
    .primary-nav {
      background-image: linear-gradient(0deg, white, white);
      background-position: right top;
      background-repeat: no-repeat;
      background-size: 0 100%;
      height: calc(100vh - 48px);
      position: absolute;
      right: 0;
      top: 100%;
      max-width: 300px;
      overflow: hidden;
      transition: background 500ms ease;
      visibility: hidden;
      width: 100vw;
      z-index: -2;

      &__items {
        display: block;
        transform: translateX(100%);
        transition: transform 500ms ease;
      }
    }

    &.is-open {
      .primary-nav {
        background-size: 100% 100%;
        transition: visibility 500ms ease, background 500ms ease;
        visibility: visible;

        &__items {
          transform: translateX(0);
        }
      }
    }
  }

  &__hamburger {
    @include mx-button-reset;
    color: $c-font-base;
    cursor: pointer;
    flex: none;
    font-size: 0;
    height: 40px;
    margin-left: 20px;
    margin-right: -10px;
    padding: 10px;
    position: relative;

    @media ($bp-header-min) {
      display: none;
    }

    span {
      background-color: currentColor;
      cursor: pointer;
      display: block;
      height: 3px;
      position: relative;
      transition: all 500ms ease-in-out;
      width: 24px;

      &::before,
      &::after {
        background-color: currentColor;
        content: "";
        cursor: pointer;
        display: block;
        height: 3px;
        position: absolute;
        right: 0;
        transition: all 500ms ease-in-out;
        width: 24px;
      }

      &::before {
        top: -6px;
      }

      &::after {
        bottom: -6px;
      }
    }
  }

  &.is-open &__hamburger span {
    background-color: transparent;

    &::before {
      top: 0;
      transform: rotate(-45deg);
    }

    &::after {
      bottom: 0;
      transform: rotate(45deg);
    }
  }
}
