@import "../00-variables/_colors.scss";
@import "../00-variables/_breakpoints.scss";

.primary-nav {
  &__items {
    list-style: none;
    margin: 0;
    padding: 0;

    @media ($bp-header-min) {
      display: flex;
    }
  }

  &__item {
    font-size: 0;
  }

  &__item + &__item {
    @media ($bp-header-max) {
      border-top: 1px solid #eee;
    }

    @media ($bp-header-min) {
      margin-left: 16px;
    }
  }

  &__link {
    color: $c-font-base;
    font-size: 18px;
    line-height: 1.5;
    transition: color 500ms ease, background 500ms ease;

    &.is-active,
    &:hover,
    &:focus {
      color: $c-rainbow-orange;
    }

    @media ($bp-header-max) {
      background-image: linear-gradient(
        0deg,
        $c-rainbow-orange,
        $c-rainbow-orange
      );
      background-position: left top;
      background-size: 0 100%;
      display: block;
      line-height: 45px;
      padding: 8px 22px;

      &:hover,
      &:focus {
        background-color: #f5f5f5;
        background-size: 8px 100%;
      }
    }
  }
}
