/* breakpoints.scss */
.primary-nav__items {
  list-style: none;
  margin: 0;
  padding: 0;
}
@media (min-width: 621px) {
  .primary-nav__items {
    display: flex;
  }
}
.primary-nav__item {
  font-size: 0;
}
@media (max-width:620px) {
  .primary-nav__item + .primary-nav__item {
    border-top: 1px solid #eee;
  }
}
@media (min-width: 621px) {
  .primary-nav__item + .primary-nav__item {
    margin-left: 16px;
  }
}
.primary-nav__link {
  color: #222;
  font-size: 18px;
  line-height: 1.5;
  transition: color 500ms ease, background 500ms ease;
}
.primary-nav__link.is-active, .primary-nav__link:hover, .primary-nav__link:focus {
  color: #e57a44;
}
@media (max-width:620px) {
  .primary-nav__link {
    background-image: linear-gradient(0deg, #e57a44, #e57a44);
    background-position: left top;
    background-size: 0 100%;
    display: block;
    line-height: 45px;
    padding: 8px 22px;
  }
  .primary-nav__link:hover, .primary-nav__link:focus {
    background-color: #f5f5f5;
    background-size: 8px 100%;
  }
}