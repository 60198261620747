/* breakpoints.scss */
.cloud-promo {
  position: relative;
  transform: rotate(-1deg);
  z-index: 2;
}
@media (min-width: 621px) {
  .cloud-promo {
    display: flex;
  }
}
.cloud-promo::before {
  background-color: #fff;
  content: "";
  position: absolute;
  top: 0;
  right: 20px;
  bottom: 0;
  left: 0;
  transform: perspective(1000px) translateZ(-200px) rotateY(-8deg) skew(-10deg) scale(1.1);
  z-index: -1;
}
@media (min-width: 781px) {
  .cloud-promo::before {
    left: -30px;
  }
}
.cloud-promo__image {
  flex: none;
  max-width: 270px;
  padding: 20px 15px;
}
@media (min-width: 621px) {
  .cloud-promo__image {
    width: 30%;
  }
}
.cloud-promo__image figure {
  display: block;
  margin: 0;
}
.cloud-promo__caption {
  font-family: "Roboto", Helvetica, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: italic;
  font-size: 14px;
  padding: 8px 16px;
}
.cloud-promo__content {
  flex: auto;
  padding: 0px 60px 40px 20px;
}
@media (min-width: 621px) {
  .cloud-promo__content {
    padding-top: 24px;
    padding-left: 40px;
  }
}
.cloud-promo__cta {
  margin-top: 16px;
}