$c-rainbow-red: #d63e3e;
$c-rainbow-orange: #e57a44;
$c-rainbow-yellow: #ece345;
$c-rainbow-green: #41c560;
$c-rainbow-blue: #0b67bc;
$c-rainbow-purple: #28265f;

$c-sky-blue: #87cefa;
$c-ground-green: #41c560;
$c-black: #222;

$c-gray-c3c3c3: #c3c3c3;

$c-theme-secondary: $c-rainbow-orange;

$c-font-base: $c-black;
$c-font-inverse: white;
$c-font-error: $c-rainbow-red;
$c-font-warning: $c-rainbow-blue;
$c-font-success: $c-rainbow-green;

$c-bg-subtle: #f5f5f5;

$c-bd-warning: $c-rainbow-blue;
$c-bd-error: $c-rainbow-red;
