.page-header {
  position: relative;
  z-index: 2;
}
.page-header::before {
  background-color: white;
  content: "";
  position: absolute;
  top: 0;
  right: -10px;
  bottom: 0;
  left: 10px;
  transform: perspective(1000px) translateZ(-200px) rotateY(18deg) skew(-10deg) translateX(8%) scale(1.2);
  z-index: -1;
}
.page-header--home .page-header__container {
  max-width: 960px;
}
.page-header__summary {
  font-size: 20px;
  padding-bottom: 20px;
}