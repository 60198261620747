@import "../00-variables/_fonts.scss";
@import "../00-variables/_colors.scss";

html {
  height: 100%;
}

body {
  background-color: $c-sky-blue;
  color: $c-font-base;
  font-family: $font-serif;
  display: flex;
  flex-direction: column;
  margin: 0;
  min-height: 100vh;
  overflow-x: hidden;
  padding: 0;
  position: relative;

  & > header,
  & > footer {
    flex: none;
  }

  & > main {
    flex: auto;
  }
}

* {
  box-sizing: border-box;
}

img {
  height: auto;
  max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
p {
  margin-bottom: 0;
}
