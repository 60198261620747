.button {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  padding: 0;
  background-color: #e57a44;
  background-image: none;
  border-left: 5px dotted #fff;
  border-right: 5px dotted #fff;
  color: #222;
  font-family: "Roboto", Helvetica, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 16px;
  font-weight: bold;
  line-height: 1.375;
  display: inline-block;
  min-width: 170px;
  padding: 10px 14px;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-rendering: optimizeLegibility;
  transition: transform 200ms ease-in-out, font-size 200ms ease-in-out;
  vertical-align: middle;
  z-index: 2;
}
.button:focus, .button:hover {
  transform: skew(-4deg) rotate(-4deg);
}