/* breakpoints.scss */
.global-header {
  font-family: "Roboto", Helvetica, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  padding-top: 5px;
  position: relative;
  z-index: 80;
}
@supports (position: sticky) {
  .global-header {
    position: sticky;
    top: 0;
  }
}
.global-header::before {
  border-bottom: 10px dotted #eee;
  background-color: white;
  content: "";
  height: 200%;
  outline: 2px solid white;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: rotate(-1deg) skew(-1deg);
  transform-origin: right bottom;
  width: 100%;
  z-index: -1;
}
.global-header__logo {
  font-size: 28px;
}
.global-header__logo a {
  color: inherit;
}
.global-header__container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  height: 48px;
}
@media (max-width:620px) {
  .global-header__container {
    position: relative;
  }
}
@media (max-width:620px) {
  .global-header .primary-nav {
    background-image: linear-gradient(0deg, white, white);
    background-position: right top;
    background-repeat: no-repeat;
    background-size: 0 100%;
    height: calc(100vh - 48px);
    position: absolute;
    right: 0;
    top: 100%;
    max-width: 300px;
    overflow: hidden;
    transition: background 500ms ease;
    visibility: hidden;
    width: 100vw;
    z-index: -2;
  }
  .global-header .primary-nav__items {
    display: block;
    transform: translateX(100%);
    transition: transform 500ms ease;
  }
  .global-header.is-open .primary-nav {
    background-size: 100% 100%;
    transition: visibility 500ms ease, background 500ms ease;
    visibility: visible;
  }
  .global-header.is-open .primary-nav__items {
    transform: translateX(0);
  }
}
.global-header__hamburger {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  padding: 0;
  color: #222;
  cursor: pointer;
  flex: none;
  font-size: 0;
  height: 40px;
  margin-left: 20px;
  margin-right: -10px;
  padding: 10px;
  position: relative;
}
@media (min-width: 621px) {
  .global-header__hamburger {
    display: none;
  }
}
.global-header__hamburger span {
  background-color: currentColor;
  cursor: pointer;
  display: block;
  height: 3px;
  position: relative;
  transition: all 500ms ease-in-out;
  width: 24px;
}
.global-header__hamburger span::before, .global-header__hamburger span::after {
  background-color: currentColor;
  content: "";
  cursor: pointer;
  display: block;
  height: 3px;
  position: absolute;
  right: 0;
  transition: all 500ms ease-in-out;
  width: 24px;
}
.global-header__hamburger span::before {
  top: -6px;
}
.global-header__hamburger span::after {
  bottom: -6px;
}
.global-header.is-open .global-header__hamburger span {
  background-color: transparent;
}
.global-header.is-open .global-header__hamburger span::before {
  top: 0;
  transform: rotate(-45deg);
}
.global-header.is-open .global-header__hamburger span::after {
  bottom: 0;
  transform: rotate(45deg);
}