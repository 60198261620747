.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal;
}
.visually-hidden:focus {
  position: static !important;
  overflow: visible;
  clip: auto;
  width: auto;
  height: auto;
}