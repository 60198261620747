/* breakpoints.scss */
.form {
  margin: 0 auto;
  max-width: 700px;
}
.form__fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}
.form__split-row {
  margin-bottom: 40px;
}
.form__split-row:last-child {
  margin-bottom: 0;
}
@media (min-width: 621px) {
  .form__split-row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -30px;
  }
  .form__split-row > * {
    margin-left: 30px;
    width: calc(50% - 30px);
  }
  .form__split-row > *:nth-last-child(2) {
    margin-bottom: 0;
  }
}
.form__summary {
  margin-bottom: 40px;
  margin-top: 25px;
}
.form__item {
  display: block;
  margin-bottom: 40px;
}
.form__item:last-child {
  margin-bottom: 0;
}
.form__item--terms {
  margin-top: 40px;
  padding-left: 35px;
  position: relative;
}
.form__label {
  display: block;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.6;
  margin-bottom: 0.5em;
}
.form__item--terms .form__label {
  font-weight: 400;
}
.form__item--terms .form__label::before {
  border: 2px solid #c3c3c3;
  content: "";
  height: 24px;
  position: absolute;
  left: 0;
  top: 0;
  width: 24px;
}
.form__item--terms .form__label::after {
  border-left: 2px solid #e57a44;
  border-bottom: 2px solid #e57a44;
  content: "";
  height: 0;
  opacity: 0;
  position: absolute;
  left: 5px;
  top: 12px;
  transform: rotate(-45deg);
  transform-origin: left top;
  transition: height 250ms ease, width 250ms ease 250ms;
  width: 0;
}
.form__item--terms input:focus ~ .form__label::before {
  outline: highlight auto 5px;
  outline: -webkit-focus-ring-color auto 5px;
}
.form__helper-label {
  display: block;
  font-size: 14px;
  line-height: 1.6;
  margin-bottom: 0.5em;
}
.form__field {
  background-color: #f5f5f5;
  border: 2px solid transparent;
  border-radius: 1px;
  color: #222;
  font-family: "Roboto", Helvetica, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 16px;
  letter-spacing: 0.25px;
  line-height: 24px;
  min-height: 48px;
  padding: 6px 10px;
  transition: border 500ms ease;
  width: 100%;
}
.form__field[readonly] {
  background-color: transparent;
  border-color: #f5f5f5;
}
.form__item--terms .form__field {
  height: 1px;
  opacity: 0;
  position: absolute;
  width: 1px;
}
.form__item--terms .form__field:checked + .form__label:after {
  height: 8px;
  opacity: 1;
  width: 14px;
}
.form__item.has-warning .form__field:not([readonly]) {
  border: 2px solid #0b67bc;
}
.form__item.has-error .form__field:not([readonly]) {
  border: 2px solid #d63e3e;
}
.form__general-success, .form__error-msg {
  color: #0b67bc;
  display: none;
  font-size: 14px;
  font-weight: bold;
  line-height: 22px;
  margin-top: 12px;
}
.form__general-success.has-error, .has-error .form__general-success, .form__error-msg.has-error, .has-error .form__error-msg {
  color: #d63e3e;
}
.form__error-msg.has-warning, .form__item.has-warning .form__error-msg, .form__error-msg.has-error, .form__item.has-error .form__error-msg {
  display: block;
}
.form__general-success, .form__general-error {
  margin-bottom: 20px;
  text-align: center;
}
.form__general-success {
  color: #41c560;
}
.form__general-success.is-visible {
  display: block;
}
.form__field[readonly] ~ .form__error-msg {
  display: none;
}

.textarea__field {
  min-height: 200px;
}

.select-input {
  /* for IE and Edge */
}
.select-input__input-wrapper {
  position: relative;
}
input:not([type=hidden]) + .select-input__input-wrapper {
  display: none;
}
.select-input__input-wrapper::after {
  border-right: 3px solid currentColor;
  border-bottom: 3px solid currentColor;
  content: "";
  height: 0.75em;
  pointer-events: none;
  position: absolute;
  bottom: 20px;
  right: 16px;
  transform: rotate(45deg);
  width: 0.75em;
}
.select-input__field {
  appearance: none;
  line-height: 30px;
  padding-right: 35px;
}
.select-input__field:disabled::-ms-expand, .select-input__field::-ms-expand {
  display: none;
}